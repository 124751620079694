import { Api } from 'utils';

class RevenueService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/revenue/get',params),
		save: async (params?: any) => Api.createResponse('admin/revenue/save',params),
	}

}

export default RevenueService;
import React from 'react';
import { Textarea, Button, PaperClip, Select } from 'components';
import { SellerService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { CloseIcon } from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalStatus extends React.Component<Props> {

	state = {
		form: {
			comments: ''
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	changeStatus = (status: number) => {
		Globals.confirm(`¿Desea ${ status == Constants.VERIFIED_STATUS.ACTIVE ? 'aceptar' : 'rechazar' } el vendedor?`,async () => {
			await SellerService.admin.verified({
				status,
				user_id: this.props.item?.id
			});
			this.props.onClose();
			Globals.showSuccess(`Se ha ${ status == Constants.VERIFIED_STATUS.ACTIVE ? 'aceptado' : 'rechazado' } correctamente el vendedor`);
		});
	}

	render() {
		return (
			<div id="modal-seller-change-status">
				<div className="container-title">
					<p className="title">¿Que desea hacer con el vendedor?</p>
					<button className="btn btn-close" onClick={ () => this.props.onClose() }>
						<img src={ CloseIcon } />
					</button>
				</div>
				<div className="row row-buttons">
					<div className="col-md-6 text-center">
						<Button type="button" onClick={ () => this.changeStatus(Constants.VERIFIED_STATUS.REJECTED) }>
							Rechazar
						</Button>
					</div>
					<div className="col-md-6 text-center">
						<Button type="button" className="accept" onClick={ () => this.changeStatus(Constants.VERIFIED_STATUS.ACTIVE) }>
							Aceptar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalStatus;
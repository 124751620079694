import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CleanIcon, AddIcon, EditIcon, DeleteIcon } from 'assets/icons';
import { Table, Pagination, Modal, Button, Input, Tooltip } from 'components';
import moment from 'moment';
import ModalCreate from './modal-create';
import { ModeratorService } from 'services';
import { Globals, checkPermission, Permissions, Constants } from 'utils';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Moderators extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Nombre y apellido',
			'Correo',
			'Acción'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null,
		modules: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Moderadores',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.loadModules();
		this.load();
	}

	loadModules = async () => {
		const res: any = await ModeratorService.admin.getModules({
			withoutLoading: true
		});
		this.setState({
			modules: res.modules
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ModeratorService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.moderators.rows,
			last_page: res.moderators.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}

	add = () => {
		this.setState({
			visible: true
		});	
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el moderador?',async () => {
			await ModeratorService.admin.delete({
				user_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-moderators">
				<Modal
		          className="modal-create-moderator"
		          visible={ visible }
		          withoutClose={ true }
		          onClose={ () => this.onClose() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalCreate
		          	item={ this.state.item }
		          	modules={ this.state.modules }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-4">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Buscar por nombre"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-2">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Moderadores" data={ this.state.data.length } rightAlign={[ 3 ]} header={ this.state.header } titleRight={
					<React.Fragment>
						{
							checkPermission(Constants.MODULES.MODERATORS,'write',this.props.user) && (
								<Button className="btn-add" onClick={ () => this.add() }>
									<img src={ AddIcon } />
									Nuevo
								</Button>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i.name }</td>
								<td>{ i.email }</td>
								<td className="vertical-center text-right" style={{ minWidth: 50 }}>
									{
										checkPermission(Constants.MODULES.MODERATORS,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.MODERATORS,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Moderators);
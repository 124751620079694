import { Api } from 'utils';

class ConnectorService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/connectors/get',params),
		update: async (params?: any) => Api.createResponse('admin/connectors/update',params)
	}

}

export default ConnectorService;
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { SellerService, AuthService } from 'services';
import { Constants, Globals, checkPermission, Permissions, Colors } from 'utils';
import { ArrowRight, CleanIcon, AddIcon, EditIcon, DeleteIcon, EyeBlackIcon } from 'assets/icons';
import ModalCreate from './modal-create';
import ModalStatus from './modal-status';
import ModalView from './modal-view';
import { Modal, Pagination, Table, Input, Button, Select, Tooltip } from 'components';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	state_id: '',
	city_id: ''
}

class Sellers extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Fecha',
			'Nombre de la Empresa',
			'Nombre',
			'Estado',
			'Ciudad',
			'Estatus',
			'Verificación',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		visible_view: false,
		visible_status: false,
		item: null,
		cities: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Vendedores',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await SellerService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.users.rows,
			last_page: res.users.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}

	add = () => {
		this.setState({
			visible: true
		});	
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el vendedor?',async () => {
			await SellerService.admin.delete({
				user_id: item.id
			});
			this.load(true);
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	getCities = async () => {
		const res: any = await AuthService.getCities({
			state_id: this.state.form.state_id,
			withoutLoading: true
		});
		this.setState({
			cities: res.cities
		});
	}

	getColor = (status: number) => {
		switch(status) {
			case Constants.USER_STATUS.ACTIVE:
				return Colors.green;
			break;

			case Constants.USER_STATUS.INACTIVE:
				return Colors.danger;
			break;
		}
	}

	getColorVerified = (status: number) => {
		switch(status) {
			case Constants.VERIFIED_STATUS.ACTIVE:
				return Colors.green;
			break;

			case Constants.VERIFIED_STATUS.INACTIVE:
				return Colors.yellow;
			break;

			case Constants.VERIFIED_STATUS.REJECTED:
				return Colors.danger;
			break;
		}
	}

	changeStatus = (item: any) => {
		Globals.confirm(`¿Desea cambiar el estatus del vendedor a ${ item.status == Constants.USER_STATUS.ACTIVE ? 'Inactivo' : 'Activo' }?`,async () => {
			await SellerService.admin.changeStatus({
				user_id: item.id
			});
			this.load(true);
		});
	}

	changeVerified = (item: any) => {
		this.setState({
			visible_status: true,
			item
		});
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_view: true,
			item
		});
	}

	onCloseView = () => {
		this.setState({
			visible_view: false,
			item: null
		});	
	}

	onCloseStatus = () => {
		this.setState({
			visible_status: false,
			item: null
		},() => this.load(true));	
	}
	
	render() {
		const { visible, visible_status, visible_view } = this.state;

		return (
			<div id="admin-sellers">
				<Modal
		          className="modal-create-seller"
		          visible={ visible }
		          withoutClose={ true }
		          onClose={ () => this.onClose() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-seller-change-status"
		          visible={ visible_status }
		          withoutClose={ true }
		          onClose={ () => this.onCloseStatus() }
		        >
		          <ModalStatus
		          	item={ this.state.item }
		            onClose={ () => this.onCloseStatus() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-seller"
		          visible={ visible_view }
		          withoutClose={ true }
		          onClose={ () => this.onCloseView() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onCloseView() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Buscar por nombre"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Select
							label="Estado"
							name="state_id"
							onChange={ (e: any) => this.change(e,() => {
								this.setState({
									form: {
										...this.state.form,
										city_id: ''
									}
								},() => {
									this.load(true);
									this.getCities();
								});
							}) }
							value={ this.state.form.state_id }
							options={ this.props.states.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-3">
						<Select
							label="Ciudad"
							name="city_id"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							value={ this.state.form.city_id }
							options={ this.state.cities.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-2">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Vendedores" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i.id }</td>
								<td>{ i.name }</td>
								<td>{ i.state?.name }</td>
								<td>{ i.city?.name }</td>
								<td className="status" style={{
									color: this.getColor(i.status),
									cursor: 'pointer'
								}} onClick={ () => {
									if (checkPermission(Constants.MODULES.SELLERS,'write',this.props.user)) {
										this.changeStatus(i);
									}
								} }>{ i.status_text }</td>
								<td className="status" style={{
									color: this.getColorVerified(i.verified),
									cursor: i.verified == Constants.VERIFIED_STATUS.INACTIVE ? 'pointer' : 'default'
								}} onClick={ () => {
									if (i.verified == Constants.VERIFIED_STATUS.INACTIVE && checkPermission(Constants.MODULES.SELLERS,'write',this.props.user)) {
										this.changeVerified(i);
									}
								} }>{ i.status_verified }</td>
								<td className="vertical-center text-right" style={{ minWidth: 50 }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ EyeBlackIcon } />
										</a>
									</Tooltip>
									{
										checkPermission(Constants.MODULES.SELLERS,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
									{
										checkPermission(Constants.MODULES.SELLERS,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		states: state.states
	}
})(Sellers);
import { Api } from 'utils';

class SellerService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/sellers/get',params),
		update: async (params?: any) => Api.createResponse('admin/sellers/update',params),
		delete: async (params?: any) => Api.createResponse('admin/sellers/delete',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/sellers/change-status',params),
		verified: async (params?: any) => Api.createResponse('admin/sellers/verified',params)
	}

}

export default SellerService;
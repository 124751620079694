import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable } from 'components';
import { SellerService, AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { connect } from 'react-redux';
import { LocationIcon } from 'assets/icons';

interface Props extends RootState {
	onClose?: any;
	item?: any;
}

class CreateSeller extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        email: '',
	        password: '',
	        city_id: '',
	        state_id: '',
	        address: '',
	        document: '',
	        phone: '',
	        postal_code: ''
		},
		cities: []
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					email: this.props.item?.email || '',
					phone: this.props.item?.phone || '',
					address: this.props.item?.address || '',
					document: this.props.item?.document || '',
					postal_code: this.props.item?.postal_code || '',
					city_id: this.props.item?.city_id || '',
					state_id: this.props.item?.state_id || '',
				}
			},() => this.getCities());
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await SellerService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
			});
			Globals.showSuccess("Se ha modificado el vendedor correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	getCities = async () => {
		const res: any = await AuthService.getCities({
			state_id: this.state.form.state_id
		});
		this.setState({
			cities: res.cities
		});
	}
	
	render() {
		return (
			<div id="modal-create-seller">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.email }
							name="email" 
							label="Correo Electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.document }
							name="document" 
							label="Nombre de Empresa"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="number"
							color="white"
							value={ this.state.form.phone }
							name="phone" 
							label="Teléfono"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.address }
							name="address" 
							label="Dirección"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="number"
							color="white"
							value={ this.state.form.postal_code }
							name="postal_code" 
							label="Código Postal"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Select
							label="Estado"
							name="state_id"
							onChange={ (e: any) => this.change(e,() => {
								this.setState({
									form: {
										...this.state.form,
										city_id: ''
									}
								},() => this.getCities());
							}) }
							value={ this.state.form.state_id }
							options={ this.props.states.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Select
							label="Ciudad"
							name="city_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.city_id }
							options={ this.state.cities.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password }
							name="password" 
							label={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-6 text-center">
								<Button type="button" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-md-6 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		states: state.states
	}
})(CreateSeller);
import React from 'react';
import { Input, Button } from 'components';
import { Globals, Constants, SocketEvents } from 'utils';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { Logo, LoginTop, LoginBottom } from 'assets/img';
import { Link } from 'react-router-dom';
import { LockIcon, ProfileIcon } from 'assets/icons';
import { emit } from 'jetemit';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Login extends React.Component<Props> {

	state = {
		form: {
			email: '',
			password: ''
		}
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Iniciar Sesión',
				onBack: null
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await AuthService.login(this.state.form);
		await this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.props.history.replace('/');
	}

	disabled = () => {
		return this.state.form.email == '' || this.state.form.password == '';
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		return (
			<div id="login">
				<img src={ LoginTop } className="login-top" />
				<img src={ LoginBottom } className="login-bottom" />
				<form onSubmit={ this.submit }>
					<div className="container-logo">
						<img src={ Logo } />
					</div>
					<div className="container-input">
						<div className="input-group">
							<div className="input-group-prepend">
								<img src={ ProfileIcon } />
							</div>
							<Input
								placeholder="Correo Electrónico"
								name="email"
								onChange={ this.change } />
						</div>
						<div className="input-group">
							<div className="input-group-prepend">
								<img src={ LockIcon } />
							</div>
							<Input
								placeholder="Contraseña"
								type="password"
								name="password"
								onChange={ this.change } />
						</div>
						<div className="row">
							<div className="col-md-12 text-center">
								<div className="recover">
									<Link to="/recover">
										¿Contraseña olvidada?
									</Link>
								</div>	
							</div>
						</div>
						<div className="text-center">
							<Button type="submit">
								Iniciar Sesión
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Login);
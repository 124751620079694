import React from 'react';
import Title from './title';
import ReactHtmlParser from 'react-html-parser';

interface Props {
	header: string[],
	headerResponsive?: string[],
	data: any,
	children: React.ReactNode,
	right?: React.ReactNode,
	title?: string,
	className?: string,
	center?: number[],
	centerResponsive?: number[],
	rightAlign?: number[],
	rightAlignResponsive?: number[],
	titleRight?: React.ReactNode
}

const Table = (props: Props) => {
	
	let content = (
		<table className={ `table table-component ${ props.className }` }>
			<thead className="thead-normal">
				<tr>
					{ props.header.map((i: string,index: number) => {
						return (
							<th className={ props.center && props.center.indexOf(index) != -1 ? 'text-center' : props.rightAlign && props.rightAlign.indexOf(index) != -1 ? 'text-right' : '' } key={ index }>{ ReactHtmlParser(i) }</th>
						)
					}) }
				</tr>
			</thead>
			<thead className="thead-responsive">
				<tr>
					{ props.headerResponsive?.map((i: string,index: number) => {
						return (
							<th className={ props.centerResponsive && props.centerResponsive.indexOf(index) != -1 ? 'text-center' : props.rightAlignResponsive && props.rightAlignResponsive.indexOf(index) != -1 ? 'text-right' : '' } key={ index }>{ i }</th>
						)
					}) }
				</tr>
			</thead>
			<tbody>
				{ props.children }
			</tbody>
		</table>
	)

	if (!props.data || props.data == 0) {
		content = (
			<h2 className="no-table-data">No se encontraron registros</h2>
		)
	}

	return (
		<div className="table-container-white">
			<div className="row">
				<div className="col-md-7">
					{
						props.title != null && (
							<Title right={ props.right } name={ props.title || '' } />
						)
					}
				</div>
				<div className="col-md-5 text-right">
					<div className="container-title-right">
						{ props.titleRight }
					</div>
				</div>
			</div>
			<div className="table-container table-container-component">
				{ content }
			</div>
		</div>
	)
}

export default Table;
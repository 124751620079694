import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { CleanIcon, AddIcon, EditIcon, DeleteIcon } from 'assets/icons';
import { Table, Pagination, Modal, Button, Input, Tooltip } from 'components';
import moment from 'moment';
import { ConnectorService } from 'services';
import { Globals, checkPermission, Permissions, Constants } from 'utils';
import ModalCreate from './modal-create';
import ReactHtmlParser from 'react-html-parser';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: ''
}

class Connectors extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nombre',
			'Precio',
			'Tipo de Cristal',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Conectores',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await ConnectorService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.connectors.rows,
			last_page: res.connectors.count
		});
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}
	
	render() {
		const { visible } = this.state;
		
		return (
			<div id="admin-connectors">
				<Modal
		          className="modal-create-moderator"
		          visible={ visible }
		          withoutClose={ true }
		          onClose={ () => this.onClose() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-4">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Buscar por nombre"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-2">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Conectores" data={ this.state.data.length } rightAlign={[ 3 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.name }</td>
								<td>{ Globals.formatMiles(i.price) }</td>
								<td>{ i.glass_type?.name }</td>
								<td className="text-right">
									{
										checkPermission(Constants.MODULES.CONNECTORS,'write',this.props.user) && (
											<Tooltip title="Editar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
													<img src={ EditIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Connectors);
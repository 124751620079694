import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { HomeService } from 'services';
import { Constants, Globals, checkPermission, Permissions } from 'utils';
import { ArrowRight } from 'assets/icons';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Home extends React.Component<Props> {

	state = {
		orders: [],
		products: [],
		sellers: [],
		revenue: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await HomeService.admin.get();
		this.setState({
			revenue: res.revenue || 0,
			orders: res.orders || [],
			sellers: res.sellers || [],
			products: res.products || []
		});
	}
	
	render() {
		return (
			<div id="admin-home">
				<div className="row">
					{
						checkPermission(Constants.MODULES.SELLERS,'read',this.props.user) && (
							<div className="col-md-6">
								<div className="container-card blue">
									<h3>Vendedores</h3>
									<table className="table">
										<thead>
											<tr>
												<th>Nombre de la Empresa</th>
												<th>Nombre</th>
												<th>Estado</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.sellers.map((item: any, index: number) => {
													return (
														<tr>
															<td>{ item?.id }</td>
															<td>{ item?.name }</td>
															<td>{ item?.state?.name }</td>
														</tr>
													)
												})
											}
											{
												this.state.sellers.length == 0 && (
													<tr>
														<td align="center" colSpan={ 3 }>No hay registros para mostrar</td>
													</tr>
												)
											}
										</tbody>
									</table>
									{
										this.state.sellers.length > 0 && (
											<Link to="/admin/sellers" className="view-more">
												Ver Más <img src={ ArrowRight } />
											</Link>
										)
									}
								</div>
							</div>
						)
					}
					{
						checkPermission(Constants.MODULES.REVENUE,'read',this.props.user) && (
							<div className="col-md-6">
								<div className="container-card">
									<h3>Ganancia por defecto</h3>
									<p className="revenue">{ Globals.formatMiles(this.state.revenue,true,'') }%</p>
								</div>
							</div>
						)
					}
					{
						checkPermission(Constants.MODULES.ORDERS,'read',this.props.user) && (
							<div className="col-md-6">
								<div className="container-card">
									<h3>Pedidos</h3>
									<table className="table">
										<thead>
											<tr>
												<th>Nº Pedido</th>
												<th>Vendedor</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.orders.map((item: any, index: number) => {
													return (
														<tr>
															<td>{ item?.number_format }</td>
															<td>{ item?.user?.name }</td>
															<td>{ Globals.formatMiles(item?.total) }</td>
														</tr>
													)
												})
											}
											{
												this.state.orders.length == 0 && (
													<tr>
														<td align="center" colSpan={ 3 }>No hay registros para mostrar</td>
													</tr>
												)
											}
										</tbody>
									</table>
									{
										this.state.orders.length > 0 && (
											<Link to="/admin/orders" className="view-more">
												Ver Más <img src={ ArrowRight } />
											</Link>
										)
									}
								</div>
							</div>
						)
					}
					{
						checkPermission(Constants.MODULES.PRODUCTS,'read',this.props.user) && (
							<div className="col-md-6">
								<div className="container-card blue">
									<h3>Productos</h3>
									<table className="table">
										<thead>
											<tr>
												<th>Nombre</th>
												<th>Precio</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.products.map((item: any, index: number) => {
													return (
														<tr>
															<td>{ item?.name }</td>
															<td>{ Globals.formatMiles(item?.price) }</td>
														</tr>
													)
												})
											}
											{
												this.state.products.length == 0 && (
													<tr>
														<td align="center" colSpan={ 4 }>No hay registros para mostrar</td>
													</tr>
												)
											}
										</tbody>
									</table>
									{
										this.state.products.length > 0 && (
											<Link to="/admin/products" className="view-more">
												Ver Más <img src={ ArrowRight } />
											</Link>
										)
									}
								</div>
							</div>
						)
					}
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Home);
import { Api } from 'utils';

class ReportService {

	static admin = {

	}

}

export default ReportService;
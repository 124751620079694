import React from 'react';
import { Input, Button, PaperClip, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Colors, Print } from 'utils';
import moment from 'moment';
import { OrderService } from 'services';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalViewDetails extends React.Component<Props> {

	printDetails = async () => {
		const res: any = await OrderService.admin.printDetails({
			quote_id: this.props.item?.id
		});
		Print(Constants.BASE_STORAGE + res.url);
	}

	render() {
		const order: any = this.props.item;

		return (
			<div id="modal-view-details">
				{
					order != null && (
						<div className="container-details">
							<p><strong>Nº Pedido:</strong> { order?.number_format }</p>
							<p><strong>Fecha:</strong> { moment(order?.created_at).format('MM-DD-YYYY') }</p>
							<p><strong>Vendedor:</strong> { order?.user?.name }</p>
							{
								order?.rooms?.map((item: any) => {
									return (
										<div className="item">
											<h3>{ item.name }</h3>
											{
												item?.measures?.map((i: any, index: number) => {
													return (
														<React.Fragment>
															<p className="title-measure"><strong>Medida { index + 1 }</strong></p>
															<p><strong>Tipo de cristal:</strong> { i?.glass_type?.name || item?.glass_type?.name }</p>
															<p><strong>Ancho:</strong> { Globals.formatMiles(i.width,true,'') } { order?.unit?.name_alternative } <strong>Alto:</strong> { Globals.formatMiles(i.height,true,'') } { order?.unit?.name_alternative }</p>
														</React.Fragment>
													)
												})
											}
										</div>
									)
								})
							}
						</div>
					)
				}
				<div className="row">
					<div className="col-md-6 text-center">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-md-6 text-center">
						<Button type="button" className="btn-close btn-print" onClick={ () => this.printDetails() }>
							Imprimir
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalViewDetails;
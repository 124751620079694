export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		SELLER: 3
	},
	USER_STATUS: {
		ACTIVE: 1,
		INACTIVE: 0
	},
	MODULES: {
		ORDERS: 1,
        MODERATORS: 2,
        PRODUCTS: 3,
        SELLERS: 4,
        REVENUE: 5,
        REPORTS: 6,
        GLASS: 7,
        TRANSFORMERS: 8,
        PACKAGING: 9,
        CONNECTORS: 10,
        COLORS: 11
	},
	VERIFIED_STATUS: {
	    INACTIVE: 0,
	    ACTIVE: 1,
	    REJECTED: 2
	},
	ORDER_STATUS: {
		TO_PAY: 0,
		PENDING: 1,
		IN_PROCESS: 2,
		FINISH: 3
	},
	UNITS: {
	    M: 1,
	    FT: 2
	},
	CONVERSION_M2: 10.764
}
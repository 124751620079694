import React from 'react';
import { Input, Button, PaperClip, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	getColor = (status: number) => {
		switch(status) {
			case Constants.USER_STATUS.ACTIVE:
				return Colors.green;
			break;

			case Constants.USER_STATUS.INACTIVE:
				return Colors.danger;
			break;
		}
	}

	getColorVerified = (status: number) => {
		switch(status) {
			case Constants.VERIFIED_STATUS.ACTIVE:
				return Colors.green;
			break;

			case Constants.VERIFIED_STATUS.INACTIVE:
				return Colors.yellow;
			break;

			case Constants.VERIFIED_STATUS.REJECTED:
				return Colors.danger;
			break;
		}
	}
	
	render() {
		const { item } = this.props;

		return (
			<div id="modal-view-seller">
				{
					item && (
						<React.Fragment>
							<p><strong>Nombre de la Empresa:</strong> { item?.id }</p>
							<p><strong>Fecha de Registro:</strong> { moment(item?.created_at).isValid() ? moment(item?.created_at).format('DD-MM-YYYY') : '' }</p>
							<p><strong>Nombre:</strong> { item?.name }</p>
							<p><strong>Correo Electrónico:</strong> { item?.email }</p>
							<p><strong>Nombre de Empresa:</strong> { item?.document || '-' }</p>
							<p><strong>Teléfono:</strong> { item?.phone || '-' }</p>
							<p><strong>Estado:</strong> { item?.state?.name || '-' }</p>
							<p><strong>Ciudad:</strong> { item?.city?.name || '-' }</p>
							<p><strong>Dirección:</strong> { item?.address || '-' }</p>
							<p><strong>Código Postal:</strong> { item?.postal_code || '-' }</p>
							<p><strong>Estatus:</strong> <span className="status" style={{
								color: this.getColor(item.status)
							}}>{ item?.status_text }</span></p>
							<p><strong>Verificación:</strong> <span className="status" style={{
								color: this.getColorVerified(item.verified)
							}}>{ item?.status_verified }</span></p>
						</React.Fragment>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-12 text-center">
						<Button type="button" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalView;
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { RevenueService } from 'services';
import { Constants, Globals, checkPermission, Permissions } from 'utils';
import { ArrowRight } from 'assets/icons';
import { Button, Input } from 'components';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Revenue extends React.Component<Props> {

	state = {
		form: {
			amount: 0
		}
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Ganancia',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await RevenueService.admin.get({
			withoutLoading
		});
		this.setState({
			form: {
				...this.state.form,
				amount: res.revenue || 0
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async (e: any) => {
		e.preventDefault();
		await RevenueService.admin.save({
			...this.state.form,
		});
		Globals.showSuccess("Se ha modificado la ganancia por defecto correctamente");
		this.load(true);
	}
	
	render() {
		return (
			<div id="admin-revenue">
				<div className="container-form">
					<h4>Ganancia por defecto</h4>
					<Input
						disabled={ !checkPermission(Constants.MODULES.REVENUE,'write',this.props.user) }
						label="Porcentaje"
						className="input-decimal"
						type="percentage"
						color="white"
						value={ this.state.form.amount }
						name="amount" 
						placeholder="0%"
						onChange={ this.change } />
					{
						checkPermission(Constants.MODULES.REVENUE,'write',this.props.user) && (
							<div className="text-center">
								<Button type="button" onClick={ this.submit }>
									Guardar
								</Button>
							</div>
						)
					}
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Revenue);
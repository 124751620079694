import { Api } from 'utils';

class GlassService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/glass/get',params),
		update: async (params?: any) => Api.createResponse('admin/glass/update',params)
	}

}

export default GlassService;
import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable } from 'components';
import { ConnectorService, AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { connect } from 'react-redux';
import { LocationIcon } from 'assets/icons';

interface Props extends RootState {
	onClose?: any;
	item?: any;
}

class Create extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        price: ''
		}
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					price: this.props.item?.price || ''
				},
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ConnectorService.admin.update({
			connector_id: this.props.item?.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha modificado el conector correctamente");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-moderator" className="modal-create-transformer">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="decimal"
							color="white"
							value={ this.state.form.price }
							name="price" 
							label="Precio"
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-6 text-center">
								<Button type="button" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-md-6 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect()(Create);
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { OrderService } from 'services';
import { Constants, Globals, checkPermission, Permissions, Colors, Print } from 'utils';
import { ArrowRight, CleanIcon, EyeBlackIcon, DownloadBlackIcon, DeleteIcon, OrderIcon } from 'assets/icons';
import { Table, Pagination, Input, Select, Button, DatePicker, Tooltip, Modal } from 'components';
import ModalView from './modal-view';
import ModalViewDetails from './modal-view-details';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	number: '',
	status: '',
	date: ''
}

class Orders extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		header: [
			'Nº Pedido',
			'Fecha',
			'Vendedor',
			'Comisión',
			'Monto Total',
			'Método de Pago',
			'Estatus',
			'Acciones'
		],
		form: INITIAL_STATE,
		visible: false,
		item: null,
		visible_details: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Pedidos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await OrderService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.orders.rows,
			last_page: res.orders.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	getColor = (item: any) => {
		switch(item.status) {
			case Constants.ORDER_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.ORDER_STATUS.IN_PROCESS:
				return Colors.yellow;
			break;

			case Constants.ORDER_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar la orden?',async () => {
			await OrderService.admin.delete({
				quote_id: item.id
			});
			this.load(true);
		});
	}

	view = async (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});	
	}

	viewOrder = async (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible_details: true,
			item
		});	
	}

	download = async (e: any, item: any) => {
		e.preventDefault();
		const res: any = await OrderService.admin.download({
			quote_id: item.id
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		});	
	}

	onCloseDetails = () => {
		this.setState({
			visible_details: false,
			item: null
		});	
	}

	changeStatus = (item: any) => {
		if (item.status != Constants.ORDER_STATUS.TO_PAY && item.status != Constants.ORDER_STATUS.FINISH) {
			let status = '';

			switch(item.status) {
				case Constants.ORDER_STATUS.PENDING:
					status = 'En proceso';
				break;

				case Constants.ORDER_STATUS.IN_PROCESS:
					status = 'Finalizado';
				break;
			}

			Globals.confirm(`¿Desea cambiar el estatus de la cotización a ${ status }?`,async () => {
				await OrderService.admin.changeStatus({
					quote_id: item.id
				});
				this.load(true);
			});
		}
	}
	
	render() {
		const { visible, visible_details } = this.state;

		return (
			<div id="admin-orders">
				<Modal
		          className="modal-view-order"
		          visible={ visible }
		          withoutClose={ true }
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-details"
		          visible={ visible_details }
		          withoutClose={ true }
		          onClose={ () => this.onCloseDetails() }
		        >
		          <ModalViewDetails
		          	item={ this.state.item }
		            onClose={ () => this.onCloseDetails() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-2">
						<Input
							value={ this.state.form.number }
							name="number"
							label="Nº Pedido"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-3">
						<Input
							value={ this.state.form.search }
							name="search"
							label="Nombre del vendedor"
							placeholder="Buscar"
							className="input-table"
							onChange={ (e: any) => {
								clearTimeout(this.timer);
								this.timer = setTimeout(() => {
									this.load(true);
								},1000);
								this.change(e); 
							} } />
					</div>
					<div className="col-md-2">
						<DatePicker
							label="Fecha"
							placeholder="Fecha"
							showYearDropdown={ true }
							maxDate={ moment().toDate() }
							onChange={ (text: string) => {
								this.setState({
									page: 1,
									form: {
										...this.state.form,
										date: text
									}						
								},() => this.load(true));
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-2">
						<Select
							label="Estatus"
							name="status"
							onChange={ (e: any) => this.change(e,() => {
								this.load(true);
							}) }
							value={ this.state.form.status }
							options={ [
								{ value: Constants.ORDER_STATUS.PENDING.toString(), label: 'Pendientes' },
								{ value: Constants.ORDER_STATUS.IN_PROCESS.toString(), label: 'En Proceso' },
								{ value: Constants.ORDER_STATUS.FINISH.toString(), label: 'Finalizados' },
							] } />
					</div>
					<div className="col-md-2">
						{
							this.state.form != INITIAL_STATE && (
								<button className="btn btn-clean" onClick={ () => this.reset() }>
									<img src={ CleanIcon } />
								</button>
							)
						}
					</div>
				</div>

				<Table title="Pedidos" data={ this.state.data.length } rightAlign={[ 7 ]} header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						const commission = i?.rooms?.map((i: any) => i.total - i.original_total).reduce((a: number, b: number) => a + b,0) 
							+ i?.products?.map((i: any) => (i.price - i.original_price) * i.quantity).reduce((a: number, b: number) => a + b,0);

						return (
							<tr key={ index }>
								<td>{ i.number_format }</td>
								<td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
								<td>{ i.user?.name }</td>
								<td>{ Globals.formatMiles(commission) }</td>
								<td>{ Globals.formatMiles(i.total) }</td>
								<td>{ i.payment?.payment_method?.name || '-' }</td>
								<td className="status" onClick={ () => this.changeStatus(i) } style={{
									color: this.getColor(i),
									cursor: (i.status == Constants.ORDER_STATUS.TO_PAY || i.status == Constants.ORDER_STATUS.FINISH) ? 'normal' : 'pointer'
								}}>{ i.status_text }</td>
								<td className="vertical-center text-right" style={{ minWidth: 50 }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ EyeBlackIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Detalles del Pedido">
										<a className="link-icon" href="#" onClick={ (e: any) => this.viewOrder(e,i) }>
											<img src={ OrderIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Descargar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.download(e,i) }>
											<img src={ DownloadBlackIcon } />
										</a>
									</Tooltip>
									{
										checkPermission(Constants.MODULES.MODERATORS,'delete',this.props.user) && (
											<Tooltip title="Eliminar">
												<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
													<img src={ DeleteIcon } />
												</a>
											</Tooltip>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Orders);
import { Api } from 'utils';

class OrderService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/orders/get',params),
		download: async (params?: any) => Api.createResponse('admin/orders/download',params),
		delete: async (params?: any) => Api.createResponse('admin/orders/delete',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/orders/change-status',params),
		printDetails: async (params?: any) => Api.createResponse('admin/orders/print-details',params),
	}

}

export default OrderService;
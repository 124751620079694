import { Api } from 'utils';

class ProductService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/products/get',params),
		create: async (params?: any) => Api.createResponse('admin/products/create',params),
		update: async (params?: any) => Api.createResponse('admin/products/update',params),
		delete: async (params?: any) => Api.createResponse('admin/products/delete',params),
		getUnits: async (params?: any) => Api.createResponse('admin/products/units',params)
	}

}

export default ProductService;
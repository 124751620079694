import { Api } from 'utils';

class TransformerService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/transformers/get',params),
		create: async (params?: any) => Api.createResponse('admin/transformers/create',params),
		update: async (params?: any) => Api.createResponse('admin/transformers/update',params),
		delete: async (params?: any) => Api.createResponse('admin/transformers/delete',params)
	}

}

export default TransformerService;
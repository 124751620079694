import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  useHistory 
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";
import { RootState } from "reducers";
import { Socket, SocketEvents, Constants, Globals } from 'utils';
import { AuthService } from 'services';
import './assets/i18n/i18n';
import i18next from 'i18next';

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Public
import Home from 'screens/home';

// Auth
import Login from "screens/auth/login/login";
import Recover from "screens/auth/login/recover";
import Reset from "screens/auth/login/reset";
import Profile from "screens/profile";

// Admin Home
import Admin from 'screens/admin/home';
import AdminHome from 'screens/admin/home/home';
import AdminModerators from 'screens/admin/moderators/moderators';
import AdminOrders from 'screens/admin/orders/orders';
import AdminProducts from 'screens/admin/products/products';
import AdminReports from 'screens/admin/reports/reports';
import AdminRevenue from 'screens/admin/revenue/revenue';
import AdminSellers from 'screens/admin/sellers/sellers';
import AdminGlassType from 'screens/admin/glass/glass';
import AdminColors from 'screens/admin/colors/colors';
import AdminTransformers from 'screens/admin/transformers/transformers';
import AdminPackaging from 'screens/admin/packaging/packaging';
import AdminConnectors from 'screens/admin/connectors/connectors';

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    i18next.changeLanguage('es');

    const getUser = async () => {
      const user = store.getState().user;
      if (user) {
        const res: any = await AuthService.checkUser({
          user_id: user?.id,
          withoutLoading: true
        });
        if (res.user) {
          if (res.user?.status == Constants.USER_STATUS.ACTIVE) {
            dispatch({
              type: 'SET_USER',
              payload: res.user
            });
          }
          else {
            Globals.showError("Lo sentimos, su usuario ha sido suspendido");
            close();
          }
        }
        else {
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
          close();
        }
      }
    }
    getUser();

    const getStates = async () => {
      const res: any = await AuthService.getStates({
        withoutLoading: true
      });
      if (res.states) {
        dispatch({
          type: 'SET_STATES',
          payload: res.states
        });
      }
    }
    getStates();

    const close = async () => {
      await dispatch({
        type: 'SET_USER',
        payload: null
      }); 
      history.replace('/logout');
    }

    Socket.on(SocketEvents.UPDATE_USER,(data: any) => {
      const user = store.getState().user;
      if (user != null && user?.id == data.user_id) {
        getUser();
      }
    });
  },[]);

  return (
    <React.Fragment>
      <Switch>
        {/* Public */}
        <Route exact path="/" component={ Home } />

        {/* Auth */}
        <Route exact noAuth path="/login" component={ Login } />
        <Route exact auth path="/profile" component={ Profile } />
        <Route exact noAuth path="/recover" component={ Recover } />
        <Route exact noAuth path="/recover/:code" component={ Reset } />

        {/* Admin */}

        {/* Home */}
        <Route exact admin path="/admin" component={ Admin } />  
        <Route exact admin path="/admin/home" component={ AdminHome } />

        {/* Moderators */}
        <Route exact admin path="/admin/moderators" component={ AdminModerators } permission={ Constants.MODULES.MODERATORS } />

        {/* Orders */}
        <Route exact admin path="/admin/orders" component={ AdminOrders } permission={ Constants.MODULES.ORDERS } />

        {/* Products` */}
        <Route exact admin path="/admin/products" component={ AdminProducts } permission={ Constants.MODULES.PRODUCTS } />

        {/* Reports */}
        {/*<Route exact admin path="/admin/reports" component={ AdminReports } permission={ Constants.MODULES.REPORTS } />*/}

        {/* Revenue */}
        <Route exact admin path="/admin/revenue" component={ AdminRevenue } permission={ Constants.MODULES.REVENUE } />

        {/* Sellers */}
        <Route exact admin path="/admin/sellers" component={ AdminSellers } permission={ Constants.MODULES.SELLERS } />

        {/* Transformers */}
        <Route exact admin path="/admin/transformers" component={ AdminTransformers } permission={ Constants.MODULES.TRANSFORMERS } />

        {/* Connectors */}
        <Route exact admin path="/admin/connectors" component={ AdminConnectors } permission={ Constants.MODULES.CONNECTORS } />

        {/* Glass Types */}
        {/*<Route exact admin path="/admin/glass" component={ AdminGlassType } permission={ Constants.MODULES.GLASS } />*/}

        {/* Colors */}
        <Route exact admin path="/admin/colors" component={ AdminColors } permission={ Constants.MODULES.COLORS } />

        {/* Packaging */}
        <Route exact admin path="/admin/packaging" component={ AdminPackaging } permission={ Constants.MODULES.PACKAGING } />

        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;

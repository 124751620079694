import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable } from 'components';
import { ColorService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { connect } from 'react-redux';
import { LocationIcon } from 'assets/icons';

interface Props extends RootState {
	onClose?: any;
	item?: any;
}

class Create extends React.Component<Props> {

	state = {
		form: {
	        price: ''
		}
	}

	componentDidMount() {
		const price_ft2 = this.props.item?.prices?.find((item: any) => item.unit_id == Constants.UNITS.FT);

		this.setState({
			form: {
				...this.state.form,
				price: price_ft2?.price || '',
			},
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ColorService.admin.update({
			color_id: this.props.item?.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha modificado el color correctamente");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-moderator" className="modal-create-transformer">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-12">
						<Input
							type="decimal"
							color="white"
							value={ this.state.form.price }
							name="price" 
							label="Precio (ft2)"
							onChange={ this.change } />
						<p className="conversion">{ ((parseFloat(this.state.form.price) || 0) * Constants.CONVERSION_M2).toFixed(2) } / m<sup>2</sup></p>
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-6 text-center">
								<Button type="button" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-md-6 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect()(Create);
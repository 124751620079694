import React from 'react';
import { CheckWhiteIcon } from 'assets/icons';

interface Props {
	active?: boolean;
	onClick?: any;
	size?: string;
}

const CheckboxTable = (props: Props) => {
	return (
		<div className={ `checkbox-table-component ${ props.size || '' } ${ props.active ? 'active' : '' }` } onClick={ props.onClick }>
			{
				props.active && (
					<img src={ CheckWhiteIcon } />
				)
			}
		</div>
	)
}

export default CheckboxTable;
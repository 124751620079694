import React from 'react';
import { Input, Button, PaperClip, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	getColor = (status: number) => {
		switch(status) {
			case Constants.ORDER_STATUS.TO_PAY:
				return Colors.danger;
			break;

			case Constants.ORDER_STATUS.PENDING:
				return Colors.danger;
			break;

			case Constants.ORDER_STATUS.IN_PROCESS:
				return Colors.yellow;
			break;

			case Constants.ORDER_STATUS.FINISH:
				return Colors.green;
			break;
		}
	}

	render() {
		const { item } = this.props;
		const commission = item?.rooms?.map((i: any) => (i.price - i.original_price) * i.quantity).reduce((a: number, b: number) => a + b,0) 
							+ item?.products?.map((i: any) => (i.price - i.original_price) * i.quantity).reduce((a: number, b: number) => a + b,0);

		return (
			<div id="modal-view-order">
				{
					item && (
						<React.Fragment>
							<p><strong>Nº Pedido:</strong> { item?.number_format }</p>
							<p><strong>Fecha:</strong> { moment(item?.created_at).format('DD-MM-YYYY') }</p>
							<p><strong>Vendedor:</strong> { item?.user?.name }</p>
							<p><strong>Comisión:</strong> { Globals.formatMiles(commission) }</p>
							<p><strong>Monto Total:</strong> { Globals.formatMiles(item?.total) }</p>
							<p><strong>Método de Pago:</strong> { item?.payment?.payment_method?.name || '-' }</p>
							<p><strong>Estatus:</strong> <span style={{
								color: this.getColor(item.status)
							}}>{ item?.status_text }</span></p>
						</React.Fragment>
					)
				}
				<div className="row row-buttons">
					<div className="col-md-12 text-center">
						<Button type="button" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalView;
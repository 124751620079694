import React from 'react';
import { Input, Button, Textarea, PaperClip } from 'components';
import { ProductService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { connect } from 'react-redux';
import { LocationIcon } from 'assets/icons';
import ReactHtmlParser from 'react-html-parser';

interface Props extends RootState {
	onClose?: any;
	item?: any;
}

class CreateProduct extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        description: '',
	        photo: '',
	        price: ''
		}
	}

	componentDidMount() {
		if (this.props.item) {
			this.setState({
				form: {
					...this.state.form,
					name: this.props.item?.name || '',
					description: this.props.item?.description || '',
					price: this.props.item?.price || ''
				}
			});
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await ProductService.admin.update({
				product_id: this.props.item?.id,
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || ''
			});
			Globals.showSuccess("Se ha modificado el producto correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
		else {
			await ProductService.admin.create({
				...this.state.form,
				hasFile: true,
				// @ts-ignore,
				photo: this.state.form.photo?.value || ''
			});
			Globals.showSuccess("Se ha creado el producto correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-create-moderator">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-12 text-center">
						<PaperClip
							className="paperclip-image"
							value={ this.state.form.photo }
							onChange={ (e: any) => {
								this.setState({
									form: {
										...this.state.form,
										photo: e.target
									}
								})
							} }>
							{/*
							// @ts-ignore */}
							<img className={ `selected-image ${ (this.state.form.photo || this.props.item?.photo) ? '' : 'inactive' }` } src={ this.state.form.photo?.dataURL || (this.props.item?.photo ? (Constants.BASE_STORAGE + this.props.item?.photo) : WhiteImage) } />
						</PaperClip>
					</div>
					<div className="col-md-12">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Input
							type="decimal"
							color="white"
							value={ this.state.form.price }
							name="price" 
							label="Precio"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Textarea
							rows={ 3 }
							color="white"
							value={ this.state.form.description }
							name="description" 
							label="Descripción"
							onChange={ this.change } />
					</div>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-6 text-center">
								<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-md-6 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		states: state.states
	}
})(CreateProduct);
import React from 'react';
import { Input, Textarea, Select, Button, PaperClip, DatePicker } from 'components';
import { ProfileService, AuthService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { PhoneIcon, WebIcon, InstagramIcon, FacebookIcon, LocationIcon, TimeIcon, AddIcon, TrashIcon } from 'assets/icons';
import { WhiteImage } from 'assets/img';

const TABS = {
	DATA: 1,
	PASSWORD: 2
}

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Profile extends React.Component<Props> {

	state = {
		form: {
			name: '',
	        email: '',
	        password: '',
	        password_confirmation: '',
	        city_id: '',
	        address: '',
	        document: '',
	        phone: '',
	        state_id: ''
		},
		step: TABS.DATA,
		user: null,
		cities: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mis Datos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await ProfileService.get({
			user_id: this.props.user.id
		});
		this.setState({
			user: res.user,
			form: {
				...this.state.form,
				name: res.user?.name || '',
		        email: res.user?.email || '',
		        phone: res.user?.phone || '',
				address: res.user?.address || '',
				document: res.user?.document || '',
				city_id: res.user?.city_id || '',
				state_id: res.user?.state_id || '',
			}
		},() => this.getCities());
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await ProfileService.update({
			user_id: this.props.user.id,
			...this.state.form,
			hasFile: true
		});
		Globals.showSuccess("Se ha actualizado el perfil correctamente");
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	setStep = (step: number) => {
		this.setState({
			step
		});
	}

	getCities = async () => {
		const res: any = await AuthService.getCities({
			state_id: this.state.form.state_id
		});
		this.setState({
			cities: res.cities
		});
	}
	
	render() {
		return (
			<div id="profile">
				<form className="row" onSubmit={ this.submit }>	
					<div className="col-md-12">
						<div className="container-form">
							<div className="container-tabs">
								<div className={ `tab ${ this.state.step == TABS.DATA ? 'active' : '' }` } onClick={ () => this.setStep(TABS.DATA) }>
									Datos
								</div>
								<div className={ `tab ${ this.state.step == TABS.PASSWORD ? 'active' : '' }` } onClick={ () => this.setStep(TABS.PASSWORD) }>
									Contraseña
								</div>
								<div className="tab-flex"></div>
							</div>
							<div className="row">
								{
									this.state.step == TABS.DATA && (
										<div className="row col-12">
											<div className="col-md-6">
												<Input
													color="white"
													value={ this.state.form.name }
													name="name" 
													label="Nombre"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Input
													color="white"
													value={ this.state.form.email }
													name="email" 
													label="Correo Electrónico"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Input
													color="white"
													value={ this.state.form.document }
													name="document" 
													label="Nombre de Empresa"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Input
													type="number"
													color="white"
													value={ this.state.form.phone }
													name="phone" 
													label="Teléfono"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Input
													color="white"
													value={ this.state.form.address }
													name="address" 
													label="Dirección"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Select
													label="Estado"
													name="state_id"
													onChange={ (e: any) => this.change(e,() => {
														this.setState({
															form: {
																...this.state.form,
																city_id: ''
															}
														},() => this.getCities());
													}) }
													value={ this.state.form.state_id }
													options={ this.props.states.map((i: any) => {
														return {
															value: i.id,
															label: i.name
														}
													}) } />
											</div>
											<div className="col-md-6">
												<Select
													label="Ciudad"
													name="city_id"
													onChange={ (e: any) => this.change(e) }
													value={ this.state.form.city_id }
													options={ this.state.cities.map((i: any) => {
														return {
															value: i.id,
															label: i.name
														}
													}) } />
											</div>
										</div>
									)
								}	
								{
									this.state.step == TABS.PASSWORD && (
										<div className="row col-12">
											<div className="col-md-6">
												<Input
													type="password"
													color="white"
													value={ this.state.form.password }
													name="password" 
													label="Nueva contraseña"
													placeholder="Nueva contraseña"
													onChange={ this.change } />
											</div>
											<div className="col-md-6">
												<Input
													type="password"
													color="white"
													value={ this.state.form.password_confirmation }
													name="password_confirmation" 
													label="Repetir contraseña"
													placeholder="Repetir contraseña"
													onChange={ this.change } />
											</div>
										</div>
									)
								}
								<div className="col-12">
									<Button type="submit">
										Guardar
									</Button>													
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		states: state.states
	}
})(Profile);
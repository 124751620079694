import { Api } from 'utils';

class ColorService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/colors/get',params),
		update: async (params?: any) => Api.createResponse('admin/colors/update',params)
	}

}

export default ColorService;
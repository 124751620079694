import React from 'react';
import { Input, Button } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { LoginTop, LoginBottom } from 'assets/img';
import { ArrowLeft, LockIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals } from 'utils';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import queryString from 'query-string';

interface Props extends RouteComponentProps<any> {
	dispatch: any;
	t: any;
}

class Reset extends React.Component<Props> {

	state = {
		visible: false,
		form: {
			password: '',
			password_confirmation: ''
		}
	}

	async componentDidMount() {
		const res: any = await AuthService.checkCode({
			code: this.props.match.params.code
		});
		if (!res.result) {
			this.props.history.replace('/');
		}
		else {
			this.setState({
				visible: true
			},() => {
				const params: any = queryString.parse(this.props.location.search);
				if (params._lang) {
					i18next.changeLanguage(params._lang);
				}
				else {
					i18next.changeLanguage('es');
				}
			});
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: this.props.t('Reset.Title'),
				onBack: null
			}
		});
	}

	disabled = () => {
		const {
			password,
			password_confirmation
		} = this.state.form;
		return password == '' || password == null ||
		       password_confirmation == '' || password_confirmation == null;
	}

	submit = async (e: any) => {
		e.preventDefault();
		await AuthService.reset({
			code: this.props.match.params.code,
			password: this.state.form.password,
			password_confirmation: this.state.form.password_confirmation
		});
		this.props.history.replace('/login');
		Globals.showSuccess(this.props.t('Reset.Success'));
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	
	render() {
		const { t } = this.props;

		return (
			<div id="reset">
				<img src={ LoginTop } className="login-top" />
				<img src={ LoginBottom } className="login-bottom" />
				<div className="container-form">
					<form onSubmit={ this.submit }>
						<div className="row row-title">
							<div className="col-2">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
							</div>
							<div className="col-8">
								<h3 className="title-green">{ t('Reset.Title') }</h3>
							</div>
						</div>
						<div className="container-input">
							<Input
								placeholder={ t('Reset.NewPassword') }
								type="password" 
								name="password"
								onChange={ this.change } />
							<Input
								placeholder={ t('Reset.ConfirmPassword') }
								type="password" 
								name="password_confirmation"
								onChange={ this.change } />
							<div className="text-center">
								<Button type="submit" disabled={ this.disabled() }>
									{ t('Reset.Save') }
								</Button>
							</div>
						</div>		
					</form>
				</div>
			</div>
		)
	}
}

export default withTranslation()(connect((state: RootState) => {
	return {
		user: state.user
	}
})(Reset));
import { store } from 'store';
import Constants from './constants';

export default {
	
}

export const checkPermission = (permission: number | number[], action: string, user: any) => {
	if (!user) {
		user = store.getState().user;
	}
	if (typeof permission === 'number') {
		return (user.level_id != Constants.LEVELS.MODERATOR) || (user.module_permissions?.filter((i: any) => i[action]).map((i: any) => i.module_id) || []).indexOf(permission) != -1;
	}
	else if (Array.isArray(permission)) {
		let value = false;
		permission.forEach((item: number) => {
			if (!value)
				value = (user.module_permissions?.filter((i: any) => i[action]).map((i: any) => i.module_id) || []).indexOf(item) != -1;
		});
		return (user.level_id != Constants.LEVELS.MODERATOR) || value;
	}
}
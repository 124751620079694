import { Api } from 'utils';

class AuthService {
	
	static login = async (params?: any) => Api.createResponse('auth/login',params);
	static checkCode = async (params?: any) => Api.createResponse('auth/check-code',params);
	static recover = async (params = {}) => Api.createResponse('auth/recover',params);
	static reset = async (params = {}) => Api.createResponse('auth/reset',params);
	static checkUser = async (params = {}) => Api.createResponse('auth/check-user',params);
	static getCities = async (params = {}) => Api.createResponse('auth/cities',params);
	static getStates = async (params = {}) => Api.createResponse('auth/states',params);

}

export default AuthService;
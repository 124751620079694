import React from 'react';
import { Input, Button, PaperClip, Select, CheckboxTable } from 'components';
import { ModeratorService, AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Socket, SocketEvents } from 'utils';
import { WhiteImage } from 'assets/img';
import { connect } from 'react-redux';
import { LocationIcon } from 'assets/icons';

interface Props extends RootState {
	onClose?: any;
	item?: any;
	modules: any;
}

class CreateModerator extends React.Component<Props> {

	state = {
		modules: [],
		form: {
			name: '',
	        email: '',
	        password: '',
	        city_id: '',
	        state_id: '',
	        address: '',
	        document: '',
	        phone: ''
		},
		cities: []
	}

	componentDidMount() {
		this.setState({
			modules: Globals.clone(this.props.modules),
		},() => {
			if (this.props.item) {
				this.setState({
					form: {
						...this.state.form,
						name: this.props.item?.name || '',
						email: this.props.item?.email || '',
						phone: this.props.item?.phone || '',
						address: this.props.item?.address || '',
						document: this.props.item?.document || '',
						city_id: this.props.item?.city_id || '',
						state_id: this.props.item?.state_id || '',
					},
					modules: Globals.clone(this.props.modules).map((i: any) => {
						if (this.props.item?.module_permissions) {
							const permission = this.props.item?.module_permissions.find((item: any) => item.module_id == i.id);
							if (permission) {
								i.read = permission.read == true;
								i.write = permission.write == true;
								i.delete = permission.delete == true;
							}
						}
						return i;
					})
				},() => this.getCities());
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (this.props.item != null) {
			await ModeratorService.admin.update({
				user_id: this.props.item?.id,
				...this.state.form,
				module_permissions: this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				})
			});
			Globals.showSuccess("Se ha modificado el moderador correctamente");
			Socket.emit(SocketEvents.UPDATE_USER,{
				user_id: this.props.item?.id
			});
			this.props.onClose();
		}
		else {
			await ModeratorService.admin.create({
				...this.state.form,
				module_permissions: this.state.modules.map((i: any) => {
					return {
						module_id: i.id,
						read: i.read,
						write: i.write,
						delete: i.delete
					}
				})
			});
			Globals.showSuccess("Se ha creado el moderador correctamente");
			this.props.onClose();
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	checkModule = (index: number, target: string) => {
		let modules: any = [...this.state.modules];
		if (target == 'active') {
			if (modules[index]['write'] && modules[index]['read'] && modules[index]['delete']) {
				modules[index]['read'] = false;
				modules[index]['write'] = false;
				modules[index]['delete'] = false;
			}
			else {
				modules[index]['read'] = true;
				modules[index]['write'] = true;
				modules[index]['delete'] = true;
			}
		}
		else {
			modules[index][target] = !modules[index][target];
		}
		this.setState({
			modules
		});
	}

	getCities = async () => {
		const res: any = await AuthService.getCities({
			state_id: this.state.form.state_id
		});
		this.setState({
			cities: res.cities
		});
	}
	
	render() {
		return (
			<div id="modal-create-moderator">
				<form className="row" onSubmit={ this.submit }>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.email }
							name="email" 
							label="Correo Electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.document }
							name="document" 
							label="Nombre de Empresa"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							type="number"
							color="white"
							value={ this.state.form.phone }
							name="phone" 
							label="Teléfono"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input
							color="white"
							value={ this.state.form.address }
							name="address" 
							label="Dirección"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Select
							label="Estado"
							name="state_id"
							onChange={ (e: any) => this.change(e,() => {
								this.setState({
									form: {
										...this.state.form,
										city_id: ''
									}
								},() => this.getCities());
							}) }
							value={ this.state.form.state_id }
							options={ this.props.states.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Select
							label="Ciudad"
							name="city_id"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.city_id }
							options={ this.state.cities.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Input
							type="password"
							color="white"
							value={ this.state.form.password }
							name="password" 
							label={ this.props.item != null ? "Cambiar Contraseña (Opcional)" : "Contraseña" }
							onChange={ this.change } />
					</div>
					<h3>Módulos Autorizados</h3>
					<table>
						<thead>
							<tr>
								<th style={{ paddingLeft: '10px' }}></th>
								<th className="text-center">Ver</th>
								<th className="text-center">Escribir</th>
								<th className="text-center" style={{ paddingRight: '10px' }}>Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{
								(this.state.modules || []).map((item: any, index: number) => {
									return (
										<tr>
											<td style={{ paddingLeft: '10px' }}>
												<div className="td-check">
													<CheckboxTable
														size="small" 
														active={ item.read && item.write && item.delete } 
														onClick={ () => this.checkModule(index,'active') } />
													<p>{ item.name }</p>
												</div>
											</td>
											<td className="td-center">
												<CheckboxTable 
													size="small" 
													active={ item.read } 
													onClick={ () => this.checkModule(index,'read') } />
											</td>
											<td className="td-center">
												<CheckboxTable 
													size="small" 
													active={ item.write } 
													onClick={ () => this.checkModule(index,'write') } />
											</td>
											<td className="td-center" style={{ paddingRight: '10px' }}>
												<CheckboxTable 
													size="small" 
													active={ item.delete } 
													onClick={ () => this.checkModule(index,'delete') } />
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
					<div className="col-12">
						<div className="row row-buttons">
							<div className="col-md-6 text-center">
								<Button type="button" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-md-6 text-center">
								<Button type="submit">
									Guardar
								</Button>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		states: state.states
	}
})(CreateModerator);
const INITIAL_STATE = {
	onBack: null,
	title: ''
}

const { REACT_APP_NAME } = process.env;

const header = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_HEADER':
			document.title = REACT_APP_NAME + ' | ' + action.payload?.title;
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default header;